import React, { useEffect, useState } from 'react';
import { useAppStateContext } from '../lib/AppStateProvider';

import { resolveListPersonalization } from '../uniform/personalization/resolveListPersonalization';
import { algoliaScoredCondition } from '../uniform/personalization/algoliaScoredCondition';
import { getCountryFromContext } from '../uniform/personalization/getCountryFromContext';

import { usePersonalizedList } from '@uniformdev/personalize-react';
import algoliasearch from 'algoliasearch';

const ALGOLIA_APPID = 'V3CV7A7CMC';
const ALGOLIA_APIKEY = '35a59b61c1577464818fdb267015c3d2';
const ALGOLIA_INDEXNAME = 'vd_en';

// Responsible for building a single tag field filter with an
// optional score value.
const buildTagField = (field, value, score) => {
  let fieldFilter = `${field}:${value}`;

  if (score) {
    fieldFilter += `<score=${score}>`;
  }

  return fieldFilter;
};

const loadCategoryAlgoliaResults = (matchedCategories) => {
  return new Promise((resolve) => {
    // The field that contains tags to match against
    const tagField = 'tags';

    // How many matches the user has, plus one because the default
    // score for Algolia is 1 on a match so boost above that slightly.
    const matchedCount = matchedCategories.length + 1;

    // Build pieces that will be passed and OR'ed together by Algolia
    const queryPieces = matchedCategories.map((category, index) => {
      const filter = buildTagField(tagField, category, matchedCount - index);
      return filter;
    });

    // Build a fallback condition so we don't filter out anything from queries.
    const fallbackCondition = buildTagField(tagField, '-tag');

    // Init the client and index.
    const client = algoliasearch(ALGOLIA_APPID, ALGOLIA_APIKEY);
    const index = client.initIndex(ALGOLIA_INDEXNAME);

    // Perform a search using the condition
    index
      .search('', {
        sumOrFiltersScores: true, // This tells Algolia to add the filter tag scores together so we get a "total" weight
        getRankingInfo: true, // Used to sort client side, highest scored item likely will not be at the top.
        facetFilters: ['type:Article', [...queryPieces, fallbackCondition]], // Query filters.
      })
      .then((response) => {
        // Extract the results and do a pass of sorting clientside.
        const { hits } = response;
        hits.sort((a, b) => b._rankingInfo.filters - a._rankingInfo.filters);
        resolve(hits);
      });
  });
};

const MTwo = React.memo(function MTwo({ fields, route }) {
  // State placeholder for results that should be personalized.
  const [results, setResults] = useState([]);
  const { appState } = useAppStateContext();
  const sitecoreContext = appState?.sitecoreContextData;

  const country_code = getCountryFromContext(sitecoreContext);

  // Extract required fields from the integrated query.
  const {
    data: {
      datasource: { intents },
      contextItem,
    },
  } = fields;

  // Resolve used conditions based on what is selected with the integrated query
  const conditions = resolveListPersonalization(intents, sitecoreContext);

  // Holder for all condition values
  const allValues = [];

  // Iterate all conditions and determine what the current user is tagged with.
  conditions &&
    Array.isArray(conditions) &&
    conditions.forEach((condition) => {
      const { handler, mappings } = condition;

      const conditionValues = handler.getValues({
        mappings,
        sitecoreContext,
      });

      if (conditionValues) {
        allValues.push(...conditionValues);
      }
    });

  // Used to fetch results from Algolia based on the conditions.
  useEffect(() => {
    (async () => {
      const hits = await loadCategoryAlgoliaResults(allValues || []);
      setResults(hits);
      refreshTracking();
    })();
  }, []);

  // Personalize the results list from Algolia
  const { personalizedList, refreshTracking } = usePersonalizedList({
    rendering: contextItem,
    route,
    list: results,
    conditions: [algoliaScoredCondition(allValues)],
  });

  return (
    <>
      <h1>Personalization for {country_code}</h1>
      <ul>
        {personalizedList &&
          personalizedList.map((scoredResult) => {
            const { item } = scoredResult;
            return (
              <li key={item.url}>
                <a href={item.url}>{item.title}</a> (<strong>Score</strong>: {item._rankingInfo.filters},{' '}
                <strong>Tags</strong>: {item.tags.join(', ')})
              </li>
            );
          })}
      </ul>
    </>
  );
});

export default MTwo;
