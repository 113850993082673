import { get } from '../../lib/getPropertyByPath';
import React from 'react';

const PreloadImages: React.FC<any> = ({rootRoute}: any) => {
  // preload header images go within the first two components usually
  const route = rootRoute.placeholders['leisure-root'].slice(0, 5);

  function getComponents(object: Record<string, any>, searchKey: string, value: string | number): object[] {
    let objects: object[] = [];

    for (let key in object) {
      if (!object.hasOwnProperty(key)) {
        continue;
      }

      if (typeof object[key] === 'object') {
        objects = objects.concat(getComponents(object[key], searchKey, value));
      } else if (key === searchKey && object[key] === value || key === searchKey && value === '') {
        objects.push(object);
      }
    }

    return objects;
  }

  const links: any[] = [];

  const images = [
    [
      'PersonalizablePrimaryHeader',
      (component: object) => get(
        component,
        'fields.data.item.personalizableImage.jss.value.src',
        get(component, 'fields.data.item.fallbackImage.jss.value.src')
      )
    ],
    [
      'HeaderMedia',
      (component: object) => get(component, 'fields.data.item.image.jss.value.src')
    ],
    [
      'HeaderCarousel',
      (component: object) => get(component, 'fields.data.item.children.0.image.jss.value.src')
    ]
  ];

  images.forEach((item: any) => {
    const [componentName, getImage] = item;
    const components = getComponents(route, 'componentName', componentName);

    if (!components[0]) {
      return;
    }

    // preload only for the first screen
    const imgSrc = getImage(components[0]);

    if (imgSrc) {
      const HeaderMediaTSXSizes = {
        mobile: '&cw=397&ch=397',
        desktop: '&cw=1556&ch=690'
      };

      links.push(...[
        <link key={0} rel="preload" as="image" href={imgSrc + HeaderMediaTSXSizes.mobile} media="(max-width: 479px)" />,
        <link key={1} rel="preload" as="image" href={imgSrc + HeaderMediaTSXSizes.desktop} media="(min-width: 480px)" />
      ]);
    } else {
      console.info(`image is not found for ${componentName} component`);
    }
  });

  return <>{links}</>;
};

export default PreloadImages;
