
import React, { useEffect } from 'react';
import { useAppStateContext } from './lib/AppStateProvider';
import { useSitecoreTracker } from '@uniformdev/tracking-react';
import { doTracking } from '@uniformdev/optimize-js';

export const Tracker: React.FunctionComponent<any> = (props: any) => {
    const { route, getLogger } = props;
    const { appState } = useAppStateContext();
    const sitecoreContext = appState.sitecoreContextData;
    const [isTrackerInitialized, setIsTrackerInitialized] = React.useState(false);


    useSitecoreTracker(sitecoreContext, {
        clientSideRouting: true,
        onInitialized: () => {
          setIsTrackerInitialized(true);
        },
        type: 'jss',
        logger: getLogger('tracker'),
        scripts: {
          optimize: `/dist/leisure/uniform.optimize.min.js`,
        },
    });
  useEffect(() => {
        const shouldNotTrack =
          !route ||
          isTrackerInitialized !== true ||
          !sitecoreContext ||
          route.itemId !== route.itemId;
        if (shouldNotTrack) {
          return;
        }

        doTracking({
          source: 'sitecore',
          context: sitecoreContext,
          debug: true,
        });
      }, [route, isTrackerInitialized, sitecoreContext]);
    return(
       null
    )

}
